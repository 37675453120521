@import "src/sass/colors.scss";

//TABLE
.container {
  padding: 40px;
}

.header {
  display: flex;
  justify-content: space-between;

  .addButton {
    width: 134px;
    height: 46px;
    border: none;
    background: $green;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
}

.tableWrapper {
  margin-top: 20px;

  .icons {
    font-size: 20px;
    display: flex;
    gap: 10px;
    cursor: pointer;
    .viewIcon {
      color: $green
    }

    .deleteIcon {
      color: $button-c-red;
    }

    .editIcon {
      color: $green
    }
  }
}

//FORM
.createButton {
  width: 100%;
  background-color: $green !important;
  height: 54px !important;
  border-radius: 10px !important;
}

.cancelButton {
  width: 100%;
  height: 54px !important;
  border-radius: 10px !important;
}