@import "src/sass/colors";

.form-text-input {
  height: 62px !important;
  border-radius: 8px !important;
}


.form-custom-multi-select {
  height: 62px !important;
  border-radius: 8px !important;
}

.form-custom-select > div{
  height: 62px !important;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
}


.multi-select-custom-tag {
  width: 142px;
  height: 40px;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid $green;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:nth-last-child(odd) {
    margin-right: 10px;
  }
}