@import "src/sass/colors.scss";

.container {
  padding: 40px;
}

.moduleRoot {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tabHeader {
  display: flex;
  justify-content: space-between;

  .addButton {
    width: 134px;
    height: 46px;
    border: none;
    background: $green;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }

}
//TABLE
.tableWrapper {
  .icons {
    font-size: 20px;
    display: flex;
    gap: 10px;
    cursor: pointer;

    justify-content: center;


    .viewIcon {
      color: $green
    }

    .deleteIcon {
      color: $button-c-red;
    }

    .editIcon {
      color: $green
    }
  }
}

//FORM

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.optionButton {
  width: 100%;
  height: 54px !important;
  border-radius: 10px !important;
}
.createButton {
  width: 100%;
  background-color: $green !important;
  height: 54px !important;
  border-radius: 10px !important;
}

.cancelButton {
  width: 100%;
  height: 54px !important;
  border-radius: 10px !important;
}

.addSublineButton {
  background-color:$light-green-table-row-delete !important;
  width: 100%;
  height: 54px !important;
  border-radius: 10px !important;
}

.sublineItem {
  display: flex;
  justify-content: space-between;
  .deleteIcon {
    cursor: pointer;
    font-size: 20px;
    color: $button-c-red;
  }
}